import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/layout";
import PageHeader from "components/page-header";
import TermsPage from "components/terms-page";
import { graphql } from "gatsby";

// Load other package css file
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/css/modal-video.min.css";
import "rc-drawer/assets/index.css";

export default function PrivacyPolicy({ data }) {
  const privacyData = data.allMarkdownRemark.nodes[0];

  const seoData = {
    title: "Privacy Policy | Fapshi",
    description:
      "This Privacy Policy describes how we collect, use, store, share, and protect personal data from Website Visitors, Customers, Merchants, and Vendors who engage with our services. We encourage you to read it keenly.",
    keywords:
      "privacy policy fapshi, fapshi privacy policy, data usage fapshi, fapshi data usage, what does fapshi do with my data, data security on fapshi, fapshi data protection, data protection on fapshi",
    pathname: "privacy-policy",
  };

  return (
    <StickyProvider>
      <Layout>
        <SEO data={seoData} />
        <PageHeader data={privacyData} />
        <TermsPage data={privacyData} />
      </Layout>
    </StickyProvider>
  );
}

export const query = graphql`
  query allPages {
    allMarkdownRemark(sort: { order: ASC, fields: id }) {
      nodes {
        frontmatter {
          title
          update
        }
        html
      }
    }
  }
`;
